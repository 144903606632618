
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import jiff from 'jiff';
import { Cliente, ClienteAssunto, Estado, Municipio } from '@/core/models/geral'
import { ClienteService } from '@/core/services/geral';
import { AlertExcludeQuestion, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { ArquivoService } from '@/core/services/shared';
import { CrudBase, Pais } from '@/core/models/shared';
import { EnderecoService } from '@/core/services/finder';
import { PessoaFisica, PessoaJuridica } from '@/core/models/shared/PessoaBase';

@Component
export default class CadastroCliente extends CrudBase {
    @Prop() private value!: string;
    @Prop() item!: Cliente;

    itemOriginal!: Cliente;
    service: ClienteService = new ClienteService();
    
    $refs!: {
        form: HTMLFormElement,
        file1: HTMLFormElement,
        file2: HTMLFormElement
    }

    panel = [0];

    fisicaJuridica: any[] = [
        { id: true, nome: "Juridica"},
        { id: false, nome: "Física"}
    ]

    foto: any = null;

    loadEndereco: boolean = false;
    enderecoService: EnderecoService = new EnderecoService();
    estadoId: number = 0;
    paises: Pais[] = [];
    estados: Estado[] = [];
    municipios: Municipio[] = [];

    dialogCadastroAssunto: boolean = false;
    clienteAssunto: ClienteAssunto = new ClienteAssunto();
    editaClienteAssunto: boolean = false;
    assuntoHeaders: any[] = [
        { text: '', value:'actions', sortable: false, class: 'action', use: true },
        { text: 'Assunto', value: 'nome', use: true },
    ]

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if(this.dialog){
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
            this.estadoId = this.item.endereco.municipio.estadoId;
        }

        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch("estadoId")
    onEstadoIdChanged(value: number) {
        this.CarregarMunicipio(value);
    }

    @Watch("item.isJuridica", {deep: true})
    WatchJuridica(){
        if(this.item.isJuridica){
            this.item.juridica = new PessoaJuridica(this.item.juridica);
            this.item.fisica = undefined;
        }
        else{
            this.item.fisica = new PessoaFisica(this.item.fisica);
            if (this.item.fisica.rg && this.item.fisica.rg.dataExpedicao) {
                this.item.fisica.rg.dataExpedicao = this.item.fisica.rg.dataExpedicao.toDateYYYYMMDD();
            }
            this.item.juridica = undefined;
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar(){
        this.enderecoService.ObterPaises().then(
            res => {
                this.paises = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        );
        this.enderecoService.ObterEstados().then(
            res => {
                this.estados = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        );
    }

    async LoadImage(){
        this.foto = this.$refs.file1.files[0];
        if (!this.foto)
            return;
        const arquivoService = new ArquivoService();
        const dados = await arquivoService.Ler(this.foto);
        this.item.logotipo = dados.replace(/^[^,]*,/, "");
    }
    RemoveImage(){
        this.item.logotipo = null;
        this.foto = null;
    }

    ObterEndereco(cep: string){
        if(cep){
            this.loadEndereco = true;
            this.enderecoService.ObterPorCep(cep).then(
                res => {
                    const endereco = res.data;
                    if (endereco) {
                        this.item.endereco.logradouro = endereco.logradouro;
                        this.item.endereco.bairro = endereco.bairro;
                        this.estadoId = endereco.estadoId;
                        this.item.endereco.municipioId = endereco.municipioId;
                        this.item.endereco.paisId = endereco.paisId;
                    }
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() => {
                this.loadEndereco = false;
            });
        }
    }

    CarregarMunicipio(estadoId: number){
        if(estadoId > 0)
            this.enderecoService.ObterMunicipios(estadoId).then(
                res => {
                    this.municipios = res.data.items;
                },
                err => AlertSimpleErr("Aviso!", err)
            );
    }

    AbrirDialogCadastroAssunto(item?: ClienteAssunto){
        if(item){
            this.editaClienteAssunto = true;
            this.clienteAssunto = item;
        }
        else{
            this.editaClienteAssunto = false;
            this.clienteAssunto = new ClienteAssunto();
        }
        this.dialogCadastroAssunto = true;
    }

    ExcluirAssunto(item: ClienteAssunto){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.assuntos.indexOf(item);
                context.item.assuntos.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    SalvarClienteAssunto(){
        if(!this.editaClienteAssunto){
            this.item.assuntos.push(this.clienteAssunto);
        }
        this.dialogCadastroAssunto = false;
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            this.loading = true;
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Close(){
        this.dialog = false;
    }
}
