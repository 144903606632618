import { Editoria } from "./Editoria";
import { Veiculo } from "./Veiculo";

export class VeiculoEditoria implements Shared.IEntity{

    id: number = 0;
    veiculoId: number = 0;
    veiculo!: Veiculo;
    editoriaId: number = 0;
    editoria!: Editoria;

    constructor(model?: VeiculoEditoria){

        if(!model)
            return;

        this.id = model.id;
        this.veiculoId = model.veiculoId;
        this.veiculo = model.veiculo;
        this.editoriaId = model.editoriaId;
        this.editoria = model.editoria;
        
    }
}