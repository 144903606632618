import { ClienteAssunto } from ".";

export class ClienteAssuntoTermo implements Shared.IEntity{

    id: number = 0;
    clienteAssuntoId: number = 0;
    clienteAssunto!: ClienteAssunto;
    nome: string = "";

    constructor(model?: ClienteAssuntoTermo){

        if(!model)
            return;

        this.id = model.id;
        this.clienteAssuntoId = model.clienteAssuntoId;
        this.clienteAssunto = model.clienteAssunto;
        this.nome = model.nome;
    }
}