import { Arquivo } from "../shared/Arquivo";
import { Noticia } from "./Noticia";

export class NoticiaVideo implements Shared.IEntity{

    id: number = 0;
    noticiaId: number = 0;
    noticia!: Noticia;
    url: string = "";
    legenda: string = "";
    credito: string = "";
    arquivo?: Arquivo;

    constructor(model?: NoticiaVideo){

        if(!model)
            return;

        this.id = model.id;
        this.noticiaId = model.noticiaId;
        this.noticia = model.noticia;
        this.url = model.url;
        this.legenda = model.legenda;
        this.credito = model.credito;
        this.arquivo = model.arquivo;
    }
}