
  import { PageBase } from '@/core/models/shared';
  import { UsuarioService } from '@/core/services/geral';
  import { AlertSimpleErr } from '@/core/services/shared/AlertService';
  import { Component, Prop } from 'vue-property-decorator';
  
  
  @Component
  export default class MasterPage extends PageBase{
    @Prop() public icone!: string;
    @Prop() public titulo!: string;
    @Prop({default: false}) public overlay!: boolean;
  
    drawer: boolean = false;
    dialogAlterarSenha: boolean = false;
  
    calculadorasMenuButtons: any[] = [];
    geralMenuButtons: any[] = [];
    operacaoMenuButtons: any[] = [];
    relatorioMenuButtons: any[] = [];
    configMenuButtons: any[] = [];
  
    created() {
      this.$vuetify.theme.dark = this.app.temaEscuro;
      this.CarregarMenu();
    }
  
    CarregarMenu(){

      this.geralMenuButtons = [
        {name:'Clientes', icon:'mdi-account-group', path:'/cadastro/cliente', restrito: this.UsuarioRestrito([this.enumUsuarioPerfil.Administrador, this.enumUsuarioPerfil.Editor, this.enumUsuarioPerfil.Cliente])},
        {name:'Editoria', icon:'mdi-newspaper', path:'/cadastro/editoria', restrito: this.UsuarioRestrito([this.enumUsuarioPerfil.Administrador, this.enumUsuarioPerfil.Editor, this.enumUsuarioPerfil.Cliente])},
        {name:'Veiculo Tipo', icon:'mdi-book-open-page-variant-outline', path:'/cadastro/veiculoTipo', restrito: this.UsuarioRestrito([this.enumUsuarioPerfil.Administrador, this.enumUsuarioPerfil.Editor, this.enumUsuarioPerfil.Cliente])},
        {name:'Veiculos', icon:'mdi-book-open-page-variant-outline', path:'/cadastro/veiculo', restrito: this.UsuarioRestrito([this.enumUsuarioPerfil.Administrador, this.enumUsuarioPerfil.Editor, this.enumUsuarioPerfil.Cliente])},
        {name:'Notícias', icon:'mdi-newspaper', path:'/cadastro/noticia', restrito: this.UsuarioRestrito([this.enumUsuarioPerfil.Administrador, this.enumUsuarioPerfil.Editor, this.enumUsuarioPerfil.Cliente])},
        {name:'Seleção', icon:'mdi-select-search', path:'/cadastro/selecao', restrito: this.UsuarioRestrito([this.enumUsuarioPerfil.Administrador, this.enumUsuarioPerfil.Editor, this.enumUsuarioPerfil.Cliente])},
      ];      
      this.configMenuButtons = [
        {name:'Usuário', icon:'mdi-account', path:'/configuracao/usuario', restrito: this.UsuarioRestrito([this.enumUsuarioPerfil.Administrador, this.enumUsuarioPerfil.Editor, this.enumUsuarioPerfil.Cliente])},
      ];
    }
  
    TemaEscuro(){
      const usuarioService = new UsuarioService();
        usuarioService.MudarTema(this.app.usuarioId).then(
          res => super.MudarTema(res.data),
          err => AlertSimpleErr("Aviso!", err)
        );
    }
  
    UsuarioRestrito(perilIds: any = []){
      if(perilIds.length > 0)
      {
        if(perilIds.find(x => x == this.app.perfilId))
          return false;
        else   
          return true;
      }
      else{
        return false;
      }
    }
  
    Logout() {
      localStorage.clear();
      this.$vuetify.theme.dark = false;
      this.$router.push({name: 'Login'});
    }
  
  }
  