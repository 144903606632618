import { EnumUsuarioPerfil } from '@/core/models/shared/Enumerados';
import Vue from 'vue'
import VueRouter, { NavigationGuardNext, RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const enumUsuarioPerfil = EnumUsuarioPerfil;

const RequireAuth = (to: any, next: NavigationGuardNext<Vue>, perfilPermitido: number[]) => {
  var sessionApp: any;
  if(localStorage.sessionApp){
    sessionApp = JSON.parse(localStorage.sessionApp);
    if(perfilPermitido.find(x => x == sessionApp.dados.perfilId)){
      next();
    }
    else{
      next({ name:"Home" });
    }
  }
  else{
    next({ name: 'Login' });
  }
};

const routes: Array<RouteConfig> = [
  { path: '/', redirect: '/home' },

  { path: '/login', name: 'Login', component: () => import('../views/shared/Login.vue') },
  { path: '/recuperarSenha/:token', name: 'LoginToken', component: () => import('../views/shared/RecuperarSenha.vue') },
  { path: '/home', name: 'Home', component: () => import('../views/shared/Home.vue') },

  //CADASTRO
  { path: '/cadastro/cliente', name: 'ListarCliente', component: () => import('../views/geral/cliente/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [enumUsuarioPerfil.Administrador])},
  { path: '/cadastro/editoria', name: 'ListarEditoria', component: () => import('../views/geral/editoria/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [enumUsuarioPerfil.Administrador])},
  { path: '/cadastro/veiculoTipo', name: 'ListarVeiculoTipo', component: () => import('../views/geral/veiculoTipo/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [enumUsuarioPerfil.Administrador])},
  { path: '/cadastro/veiculo', name: 'ListarVeiculo', component: () => import('../views/geral/veiculo/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [enumUsuarioPerfil.Administrador])},
  { path: '/cadastro/noticia', name: 'ListarNoticia', component: () => import('../views/geral/noticia/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [enumUsuarioPerfil.Administrador])},
  { path: '/detalhar/noticia/:clienteNoticiaId', name: 'DetalharNoticia', component: () => import('../views/geral/noticia/Detalhar.vue')},
  { path: '/cadastro/selecao', name: 'ListarSelecao', component: () => import('../views/geral/selecao/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [enumUsuarioPerfil.Administrador])},

  //CONFIGURACAO
  { path: '/configuracao/usuario', name: 'ListarUsuario', component: () => import('../views/geral/usuario/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [enumUsuarioPerfil.Administrador]) },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
