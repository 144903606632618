import { PessoaBase } from "../shared/PessoaBase";
import { ClienteAssunto } from ".";
import { ClienteNoticia } from "./ClienteNoticia";

export class Cliente extends PessoaBase implements Shared.IEntity {

    id: number = 0;
    logotipo: any = null;

    assuntos: ClienteAssunto[] = [];
    noticias: ClienteNoticia[] = [];

    constructor(model?: Cliente){

        super(model);

        if(!model)
            return;

        this.id = model.id;
        this.logotipo = model.logotipo;

        this.assuntos = model.assuntos;
        this.noticias = model.noticias;
    }
}