import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";
import { VincularClienteNoticiaModel } from "@/core/models/geral/models";

export class NoticiaService extends Service{
    
    constructor(){
        super('v1/noticia');
    }

    public AutoComplete(q: string) {
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
            params: {
                q: q
            },
            headers: httpHeader.headers
        });
    }

    private MontaFiltro(parametros: string, filter: any){
        if(filter){
            console.log(filter);
            let paramTemp = '';

            if(filter.titulo){
                if (paramTemp) { paramTemp += ' and '; }
                    paramTemp += `Contains(titulo, '${filter.titulo}')`;
            }

            if(filter.veiculoId){
                if (paramTemp) { paramTemp += ' and '; }
                    paramTemp += `veiculoId eq ${filter.veiculoId}`;
            }

            if(filter.dataPublicacaoInicio){
                if (paramTemp) { paramTemp += ' and '; }
                    paramTemp += `dataPublicacao ge ${filter.dataPublicacaoInicio}`;
            }

            if(filter.dataPublicacaoFim){
                if (paramTemp) { paramTemp += ' and '; }
                    paramTemp += `dataPublicacao le ${filter.dataPublicacaoFim}`;
            }

            if(filter.ignorar.length > 0){
                if (paramTemp) { paramTemp += ' and '; }
                filter.ignorar.forEach((element, index) => {
                    if(index == 0)
                        paramTemp += `id ne ${element}`;
                    else
                        paramTemp += ` and id ne ${element}`;
                });
            }            

            if (paramTemp) {

                if (parametros) {
                    parametros += '&';
                }
                else {
                    parametros += '?';
                }

                parametros += `$filter=${paramTemp}`;
            }
        }

        return parametros;
    }

    ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?: string) {
        let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);
        parametros = this.MontaFiltro(parametros, filter);
        
        return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
    }

    Similar(id: number) {
        return http.get(`${this.GetNomeControle()}/${id}/Similar`, {
            headers: httpHeader.headers
        });
    }

    Semelhantes(id: number, clienteId: number) {
        return http.get(`${this.GetNomeControle()}/${id}/Cliente/${clienteId}/Semelhantes`, {
            headers: httpHeader.headers
        });
    }

    Termos(id: number, clienteId: number) {
        return http.get(`${this.GetNomeControle()}/${id}/Cliente/${clienteId}/Termos`, {
            headers: httpHeader.headers
        });
    }

    Midias(id: number) {
        return http.get(`${this.GetNomeControle()}/${id}/Midias`, {
            headers: httpHeader.headers
        });
    }

    Lista(busca: string, clienteId: number, assuntoId?: number, termoId?: number, veiculoId?: number, tipoVeiculoId?: number, pais?: string, estado?: string, municipio?: string, dataPublicacaoInicial?: string, dataPublicacaoFinal?: string, dataColetaInicial?: string, dataColetaFinal?: string){
        return http.get(`${this.GetNomeControle()}/Lista`, {
            params: {
                busca,
                clienteId,
                assuntoId,
                termoId,
                veiculoId,
                tipoVeiculoId,
                pais,
                estado,
                municipio,
                dataPublicacaoInicial,
                dataPublicacaoFinal,
                dataColetaInicial,
                dataColetaFinal
            }
        });
    }

    AvaliarClienteNoticia(clienteNoticiaId: number, tipoId: number) {
        return http.post(`${this.GetNomeControle()}/AvaliarClienteNoticia/${clienteNoticiaId}`, undefined, {
            params: {
                tipoId
            }
        });
    }

    ClienteNoticiaMotivacao(clienteNoticiaId: number, motivacaoId: number) {
        return http.post(`${this.GetNomeControle()}/ClienteNoticiaMotivacao/${clienteNoticiaId}`, undefined, {
            params: {
                motivacaoId
            }
        });
    }

    VincularCliente(model: VincularClienteNoticiaModel){
        return http.post(`${this.GetNomeControle()}/Vincular`, model);
    }

    DesvilcularCliente(clienteNoticiaId: number, justificativa: string) {
        return http.post(`${this.GetNomeControle()}/Desvincular`, undefined, {
            params: {
                clienteNoticiaId,
                justificativa
            }
        });
    }

    public Salvamento(id: number, modelo: any, arquivos: any) : Promise<any> {
        let bodyFormData = new FormData();
        bodyFormData.append("model", JSON.stringify(modelo));
        arquivos.forEach(arquivo => {
            bodyFormData.append("arquivo", new Blob([arquivo]), arquivo.name)
        });

        if (id > 0){
            return http.patch(`${this.GetNomeControle()}/${id}/Salvamento`, bodyFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json'
                }
            });
        }
        

        return http.post(`${this.GetNomeControle()}/Salvamento`, bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            }
        });
    }

    DownloadDocumento(id: number, itemId: number, tipo: string){
        return http.get(`${this.GetNomeControle()}/${id}/DownloadDocumento/${itemId}`, {
            params: {
                tipo
            },
            responseType: 'arraybuffer'
        });
    }
}