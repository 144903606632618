
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { CrudBase } from '@/core/models/shared';
import { Selecao } from '@/core/models/geral';
import { SelecaoService } from '@/core/services/geral';

@Component
export default class CadastroSelecao extends CrudBase {
    @Prop() private value!: string;
    @Prop() item!: Selecao;

    itemOriginal!: Selecao;
    service: SelecaoService = new SelecaoService();
    $refs!: {
        form: HTMLFormElement
    }

    listagemClienteNoticiaHeaders: any = [
        { text: '', value: 'actions' },
        { text: 'Titulo', value: 'clienteNoticia.noticia.titulo' },
        { text: 'Veículo', value: 'clienteNoticia.noticia.veiculo.nome' },
        { text: 'Publicação', value: 'clienteNoticia.noticia.dataPublicacao' },
        { text: 'Coleta', value: 'clienteNoticia.noticia.dataColeta' },
    ];

    opcoesPdf: any = {
        numeroColunas: 1,
        tipoSumario: 1,
        textoCapaTitulo: "",
        rodape: "",
        legenda: true,
        paginaNumerada: true,
        repercussao: false,
        capasJornais: false,
        mancha: true,
        aberturaSecao: false,
        sinopse: true,
        capa: true,
        folhaRosto: true,
        texto: true,
        comentarioFolhaRosto: "",
        nomeArquivo: ""
    }

    colunas: any = [
        { id: 1, nome: "1"},
        { id: 2, nome: "2"},
        { id: 3, nome: "3"} 
    ]

    sumario: any = [
        { id: 0, nome: "Nenhum"},
        { id: 1, nome: "Completo"},
        { id: 2, nome: "Simples"}
    ]

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
            let teste = this.item.nome.replaceAll("ç", "c").replaceAll("Ç", "C")
                .replaceAll("ã", "a").replaceAll("Ã", "A")
                .replaceAll("õ", "o").replaceAll("Õ", "O").replace(/[^a-zA-Z0-9 ]/g, '-');
            this.opcoesPdf.nomeArquivo = teste;
        }

        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch("dialog")
    Dialog() {
        if(this.dialog){
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar(){
        
    }

    RemoverEspeciais(){
        this.opcoesPdf.nomeArquivo = this.opcoesPdf.nomeArquivo.replaceAll("ç", "c").replaceAll("Ç", "C")
            .replaceAll("ã", "a").replaceAll("Ã", "A").replaceAll("â", "a").replaceAll("Â", "A")
            .replaceAll("ê", "e").replaceAll("Ê", "E").replaceAll("ô", "o").replaceAll("Ô", "O")
            .replaceAll("õ", "o").replaceAll("Õ", "O").replace(/[^a-zA-Z0-9 ]/g, '-');
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            this.loading = true;
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    CriarPDF(id: number){
        this.service.CriarPDF(id).then(
            res => {
                this.service.DownloadArquivo(res, this.opcoesPdf.nomeArquivo);
            },
            err => {
                AlertSimple('Aviso', this.service.ErroArquivo(err), 'error');
            }
        )
    }

    BaixarMidia(id: number){
        this.service.BaixarMidia(id).then(
            res => {
                this.service.DownloadArquivo(res, this.opcoesPdf.nomeArquivo);
            },
            err => {
                AlertSimple('Aviso', this.service.ErroArquivo(err), 'error');
            }
        )
    }

    LerNoticia(item: any){
        let routeLink = this.$router.resolve({name: "DetalharNoticia",
            params: {
                id: item.clienteNoticia.noticiaId
            }
        });
        window.open(routeLink.href, '_blank');
    }
    
    Close(){
        this.dialog = false;
    }
}
