import { Cliente, ClienteAssuntoTermo, Veiculo } from ".";

export class ClienteAssunto implements Shared.IEntity{

    id: number = 0;
    clienteId: number = 0;
    cliente!: Cliente;
    nome: string = "";

    termos: ClienteAssuntoTermo[] = [];

    constructor(model?: ClienteAssunto){

        if(!model)
            return;

        this.id = model.id;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
        this.nome = model.nome;

        this.termos = model.termos;
    }
}