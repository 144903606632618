import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";

export class ClienteService extends Service {
    
    constructor(){
        super('v1/cliente');
    }

    public GetFist(){
        return http.get(`${this.GetNomeControle()}/GetFirst`);
    }

    public AutoComplete(q: string) {
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
            params: {
                q: q
            },
            headers: httpHeader.headers
        });
    }

    public Assuntos(id: number) {
        return http.get(`${this.GetNomeControle()}/${id}/Assuntos`);
    }

    public ClienteNoticia(clienteNoticiaId: number) {
        return http.get(`${this.GetNomeControle()}/${clienteNoticiaId}/ClienteNoticia`);
    }
}