
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase } from '@/core/models/shared';
import { NoticiaVideo } from '@/core/models/geral';
import { Arquivo } from '@/core/models/shared/Arquivo';

@Component
export default class CadastroNoticiaVideo extends CrudBase {
    @Prop() item!: NoticiaVideo;
    @Prop() private value!: boolean;

    $refs!: {
        form: HTMLFormElement
    }

    arquivo: any = null;

    @Watch('value')
    Value(){
        this.dialog = this.value;
    }

    @Watch('arquivo')
    WatchArquivo(){
        if(this.arquivo){
            this.item.arquivo = new Arquivo();
            this.item.arquivo.nome = this.arquivo.name;
            this.item.arquivo.tamanho = this.arquivo.size;
        }
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.$emit('salvou', this.item);
            if(this.arquivo){
                this.$emit('arquivo', this.arquivo);
            }
            this.Close();
        }
    }

    Close(){
        this.arquivo = null;
        this.dialog = false;
        this.$emit('fechou');
    }
}
