<template>
    <div :id="key"></div>
</template>

<script>

export default {
    props:{
        dados:{
            required: true
        }
    },
    data: () => ({
        key: 'ada4sd8a9sd779654'
    }),
    created() {
        this.key = Math.random(36).toString();
    },
    mounted(){  
        google.charts.load('current', {'packages':['corechart']});
        google.charts.setOnLoadCallback(this.draw);
    },
    methods: {
        draw(){
            var dados_chart = new google.visualization.arrayToDataTable(this.dados);
            var options = {
                chartArea:{width:"100%",height:"100%"},
                pieHole: 0.4,
                legend: 'left'
            };
            var chart = new google.visualization.PieChart(document.getElementById(this.key));
            chart.draw(dados_chart, options);
        }
    }
}
</script>