
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase } from '@/core/models/shared';
import { ClienteAssunto, ClienteAssuntoTermo, Veiculo } from '@/core/models/geral';
import { AlertExcludeQuestion } from '@/core/services/shared/AlertService';
import { VeiculoService } from '@/core/services/geral';

@Component
export default class CadastroAssunto extends CrudBase {    
    @Prop() private value!: string;
    @Prop() item!: ClienteAssunto;

    validItem: boolean = true;
    $refs!: {
        form: HTMLFormElement,
        formItem: HTMLFormElement
    }

    assuntoItem: ClienteAssuntoTermo = new ClienteAssuntoTermo();
    termoHeaders: any[] = [
        { text: '', value:'actions', sortable: false, class: 'action'},
        { text: 'Termo / Palavra-chave', value: 'nome'},
    ]

    veiculos: Veiculo[] = [];
    veiculoService: VeiculoService = new VeiculoService();

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
        if (this.$refs.formItem) {
            this.$refs.formItem.resetValidation();
        }
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    mounted() {
        this.veiculoService.ListarTudo().then(
            res => {
                this.veiculos = res.data.items;
            }
        )
    }

    AdicionarCadastroItem(){
        if(this.$refs.formItem.validate()){
            this.item.termos.push(this.assuntoItem);
            this.assuntoItem = new ClienteAssuntoTermo();
            if (this.$refs.formItem) {
                this.$refs.formItem.resetValidation();
            }
        }
    }

    ExcluirItem(item: ClienteAssuntoTermo){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.termos.indexOf(item);
                context.item.termos.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    Salvar(){
        if(this.$refs.form.validate()){
            this.Salvou();
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }

    Close(){
        this.$emit("fechou");
        this.dialog = false;
    }
}
