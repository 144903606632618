import { Usuario } from "../geral";

export class Operacao {

    dataHora?: string;
    usuarioId?: number;
    usuario!: Usuario;
    justificativa: string = "";

    constructor(model?: Operacao){

        if(!model)
            return;

        this.dataHora = model.dataHora;
        this.usuarioId = model.usuarioId;
        this.usuario = model.usuario;
        this.justificativa = model.justificativa;
    }
}