import { Selecao, ClienteNoticia } from ".";

export class SelecaoClienteNoticia implements Shared.IEntity{
    
    id: number = 0;
    selecaoId: number = 0;
    selecao!: Selecao;
    clienteNoticiaId: number = 0;
    clienteNoticia!: ClienteNoticia;

    constructor(model?: SelecaoClienteNoticia){

        if(!model)
            return;

        this.id = model.id;
        this.selecaoId = model.selecaoId;
        this.selecao = model.selecao;
        this.clienteNoticiaId = model.clienteNoticiaId;
        this.clienteNoticia = model.clienteNoticia;
    }
}