import { http } from "@/core/ApiConfig";
import { Service } from "../shared";

export class SelecaoService extends Service {

    constructor() {
        super('v1/selecao');
    }

    CriarPDF(id: number){
        return http.get(`${this.GetNomeControle()}/${id}/CriarPDF`, {
            responseType: 'arraybuffer'
        });
    }

    BaixarMidia(id: number){
        return http.get(`${this.GetNomeControle()}/${id}/BaixarMidia`, {
            responseType: 'arraybuffer'
        });
    }

}