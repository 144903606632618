import { Complementar } from "../shared";
import { Cliente, UsuarioPerfil } from ".";

export class Usuario implements Shared.IEntity{

    id: number = 0;
    empresaId: number = 0;    
    login: string = "";
    senha: string = "";
    dataHoraCriacao?: string;
    foto: any = null;
    perfilId: number = 1;
    perfil!: UsuarioPerfil;
    token: string = "";
    complementar: Complementar = new Complementar();
    primeiroAcesso: boolean = true;
    ativo: boolean = true;
    temaEscuro: boolean = false;
    clienteId?: number;
    cliente!: Cliente;

    constructor(model?: Usuario){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;        
        this.login = model.login;
        this.senha = model.senha;
        this.dataHoraCriacao = model.dataHoraCriacao;
        this.foto = model.foto;
        this.perfilId = model.perfilId;
        this.perfil = model.perfil;
        this.token = model.token;
        this.complementar = model.complementar;
        this.primeiroAcesso = model.primeiroAcesso;
        this.ativo = model.ativo;
        this.temaEscuro = model.temaEscuro;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
    }
}