
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase, Pais } from '@/core/models/shared';
import { Estado, Municipio, VeiculoLocalidade } from '@/core/models/geral';
import { EnderecoService } from '@/core/services/finder';
import { AlertSimpleErr } from '@/core/services/shared/AlertService';

@Component
export default class CadastroVeiculoLocalidadeNoticia extends CrudBase {
    @Prop() item!: VeiculoLocalidade;
    @Prop() private value!: boolean;

    paises: Pais[] = [];
    estados: Estado[] = [];
    municipios: Municipio[] = [];
    enderecoService: EnderecoService = new EnderecoService();

    $refs!: {
        form: HTMLFormElement
    }

    @Watch('value')
    Value(){
        this.dialog = this.value;
        if(this.dialog){
            this.Carregar();
        }
    }

    @Watch("item.estado")
    WatchEstado(){
        this.municipios = [];
        this.item.municipio = "";
        if(!(!!this.item.estado))
            return;

        var estado = this.estados.find(x => x.uf == this.item.estado)!;

        this.enderecoService.ObterMunicipios(estado.id).then(
            res => this.municipios = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Carregar(){
        this.enderecoService.ObterPaises().then(
            res => {
                this.paises = res.data.items
                this.item.pais = "BRASIL";
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.enderecoService.ObterEstados().then(
            res => {
                this.estados = res.data.items
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.Salvou();    
        }
    }

    Salvou(){
        this.$emit('salvou');
        this.Close();
    }

    Close(){
        this.dialog = false;
        this.$emit('fechou');
    }
}
