import Vue from 'vue';

//SISTEMA
import MasterPage from "./shared/MasterPage.vue";
import TextSearch from "./shared/TextSearch.vue";
import AlterarSenha from './shared/AlterarSenha.vue';
import Avatar from "./shared/Avatar.vue";
import BreadCrumb from "./shared/BreadCrumb.vue";
import ListaHeader from './shared/ListaHeader.vue';
import RelatorioPage from './shared/RelatorioPage.vue';
import wysiwyg from "vue-wysiwyg";

//CHARTS
import BarChartHorizontal from './shared/googleChart/BarChartHorizontal.vue';
import BarChartVertical from './shared/googleChart/BarChartVertical.vue';
import DonutChart from './shared/googleChart/DonutChart.vue'
import GeoChartProvinces from './shared/googleChart/GeoChartProvinces.vue';
import GeoChartWorld from './shared/googleChart/GeoChartWorld.vue';
import PieChart from './shared/googleChart/PieChart.vue';

//NOTICIA
import CadastroNoticia from './geral/noticia/Cadastro.vue';
import CadastroNoticiaImagem from './geral/noticia/CadastroImagem.vue';
import CadastroNoticiaVideo from './geral/noticia/CadastroVideo.vue';
import CadastroNoticiaAudio from './geral/noticia/CadastroAudio.vue';
import CadastroVincularCliente from './geral/noticia/CadastroVincularCliente.vue';

//SELECAO
import CadastroSelecao from './geral/selecao/Cadastro.vue'
import CadastroSelecaoGerenciar from './geral/selecao/Gerenciar.vue'

// FONTE TIPO
import CadastroVeiculoTipo from './geral/veiculoTipo/Cadastro.vue';

// FONTE
import CadastroVeiculo from './geral/veiculo/Cadastro.vue';
import CadastroVeiculoLocalidade from './geral/veiculo/CadastroLocalidade.vue';

//CLIENTE
import CadastroCliente from './geral/cliente/Cadastro.vue';
import CadastroClienteAssunto from './geral/cliente/CadastroAssunto.vue';

//EDITORIA
import CadastroEditoria from './geral/editoria/Cadastro.vue'

//CONFIGURACAO
import CadastroUsuario from './geral/usuario/Cadastro.vue';

//SISTEMA
Vue.component("master-page", MasterPage);
Vue.component("text-search", TextSearch);
Vue.component("alterar-senha", AlterarSenha);
Vue.component("avatar-component", Avatar);
Vue.component("breadCrumb-component", BreadCrumb);
Vue.component("lista-header", ListaHeader);
Vue.component("relatorio-page", RelatorioPage);
Vue.use(wysiwyg, {
    hideModules: {
        "image": true,
        "table": true
    }
}); 

//CHARTS
Vue.component("bar-chart-horizontal", BarChartHorizontal);
Vue.component("bar-chart-vertical", BarChartVertical);
Vue.component("donut-chart", DonutChart);
Vue.component("geo-chart-provinces", GeoChartProvinces);
Vue.component("geo-chart-world", GeoChartWorld);
Vue.component("pie-chart", PieChart);

//NOTICIA
Vue.component("cadastro-noticia", CadastroNoticia);
Vue.component("cadastro-noticia-imagem", CadastroNoticiaImagem);
Vue.component("cadastro-noticia-video", CadastroNoticiaVideo);
Vue.component("cadastro-noticia-audio", CadastroNoticiaAudio);
Vue.component("cadastro-vincular-cliente", CadastroVincularCliente);

//SELECAO
Vue.component("cadastro-selecao", CadastroSelecao);
Vue.component("cadastro-selecao-gerenciar", CadastroSelecaoGerenciar);

//FONTE TIPO
Vue.component("cadastro-veiculo-tipo", CadastroVeiculoTipo);

//FONTE
Vue.component("cadastro-veiculo", CadastroVeiculo);
Vue.component("cadastro-veiculo-localidade", CadastroVeiculoLocalidade);

//CLIENTE
Vue.component("cadastro-cliente", CadastroCliente);
Vue.component("cadastro-cliente-assunto", CadastroClienteAssunto);

//EDITORIA
Vue.component("cadastro-editoria", CadastroEditoria);

//CONFIGURACAO
Vue.component("cadastro-usuario", CadastroUsuario);
