import { Noticia } from "./Noticia";

export class VeiculoLocalidade implements Shared.IEntity{

    id: number = 0;
    pais: string = "";
    estado: string = "";
    municipio: string = "";
    veiculoId: number = 0;
    veiculo!: Noticia;

    constructor(model?: VeiculoLocalidade){

        if(!model)
            return;

        this.id = model.id;
        this.pais = model.pais;
        this.estado = model.estado;
        this.municipio = model.municipio;
        this.veiculoId = model.veiculoId;
        this.veiculo = model.veiculo;
    }
}