import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";

export class EditoriaService extends Service {

  constructor() {
    super('v1/editoria');
  }

  public AutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
      params: {
        q: q
      },
      headers: httpHeader.headers
    });
  }
  
}