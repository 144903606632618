
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase } from '@/core/models/shared';
import { Cliente, ClienteAssuntoTermo, Noticia } from '@/core/models/geral';
import { ClienteService, NoticiaService } from '@/core/services/geral';
import { AlertSimple, AlertSimpleErr } from '@/core/services/shared/AlertService';

@Component
export default class CadastroVincularCliente extends CrudBase {
    @Prop() private value!: boolean;

    termos: ClienteAssuntoTermo[] = [];
    termosSelected: any[] = [];
    termosHeaders: any[] = [
        { text: '', value:'actions' ,sortable: false, class: 'action', use: true },
        { text: 'Termo', value: 'nome', use: true }
    ];

    clientes: Cliente[] = [];
    clienteService: ClienteService = new ClienteService();
    onSearchCliente: any = null;
    isClienteLoading: boolean = false;

    noticias: Noticia[] = [];
    noticiaService: NoticiaService = new NoticiaService();
    onSearchNoticia: any = null;
    isNoticiaLoading: boolean = false;

    model: any = {
        clienteId: 0,
        noticiaId: 0,
        termos: []
    }

    $refs!: {
        form: HTMLFormElement
    }
    
    @Watch('value')
    Value(){
        this.dialog = this.value;
    }

    @Watch('onSearchCliente')
    searchCliente (val: string) {
        if (this.model.clienteId) return;
        if (this.isClienteLoading) return;
        if (!val) return;
        this.isClienteLoading = true
        this.clienteService.AutoComplete(val).then(
            res => {
                this.clientes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isClienteLoading = false));
    }

    @Watch('onSearchNoticia')
    searchNoticia (val: string) {
        if (this.model.noticiaId) return;
        if (this.isNoticiaLoading) return;
        if (!val) return;
        this.isNoticiaLoading = true
        this.noticiaService.AutoComplete(val).then(
            res => {
                this.noticias = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isNoticiaLoading = false));
    }

    @Watch('model.clienteId')
    WatchCliente(){
        if(!(!!this.model.clienteId))
            return;
        
        this.clienteService.ObterPorId(this.model.clienteId, "Assuntos.Termos").then(
            res => {
                let cliente = res.data;
                cliente.assuntos.forEach(assunto => {
                    assunto.termos.forEach(termo => {
                        this.termos.push(termo);
                    });
                });
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Vincular(){
        if (this.$refs.form.validate()) {

            this.termosSelected.forEach(termo => {
                let item = {
                    clienteAssuntoTermoId: termo.id
                };
                this.model.termos.push(item)
            });

            if(!(this.model.termos.length > 0)){
                AlertSimple("Aviso!", "Nenhum termo selecionado", "warning");
                return;
            }

            this.loading = true;
            this.noticiaService.VincularCliente(this.model).then(
                res => {
                    this.$emit('salvou');
                    this.Close();
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Clear(){
        this.termosSelected = [];
        this.model = {
            clienteId: 0,
            noticiaId: 0,
            termos: []
        }
    }

    Close(){
        this.Clear();
        this.$emit('fechou');
        this.dialog = false;
    }
}
