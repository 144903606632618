import { Cliente, SelecaoClienteNoticia } from ".";
import { Operacao } from "../shared";

export class Selecao implements Shared.IEntity{
    
    id: number = 0;
    nome: string = "";
    clienteId: number = 0;
    cliente!: Cliente;
    observacao: string = "";
    sinopse: string = "";

    criacao: Operacao = new Operacao();
    envio!: Operacao;

    noticias: SelecaoClienteNoticia[] = [];

    constructor(model?: Selecao){

        if(!model)
            return;

        this.id = model.id;
        this.nome = model.nome;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
        this.observacao = model.observacao;
        this.sinopse = model.sinopse;

        this.criacao = model.criacao;
        this.envio = model.envio;

        this.noticias = model.noticias;
    }
}