export class VeiculoTipo implements Shared.IEntity{

    id: number = 0;
    nome: string = "";
    importado: boolean = false;

    constructor(model?: VeiculoTipo){

        if(!model)
            return;

        this.id = model.id;
        this.nome = model.nome;
        this.importado = model.importado;
    }
}