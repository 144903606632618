
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { EditoriaService, VeiculoService, VeiculoTipoService } from '@/core/services/geral';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Veiculo } from '@/core/models/geral/Veiculo';
import { CrudBase } from '@/core/models/shared';
import { Editoria, VeiculoEditoria, VeiculoLocalidade, VeiculoTipo } from '@/core/models/geral';

@Component
export default class CadastroVeiculo extends CrudBase {
    @Prop() private value!: string;
    @Prop() item!: Veiculo;

    itemOriginal!: Veiculo;
    service: VeiculoService = new VeiculoService();
    $refs!: {
        form: HTMLFormElement,
        formEditoria: HTMLFormElement
    }

    veiculoTipos: VeiculoTipo[] = [];
    veiculoTipoService: VeiculoTipoService = new VeiculoTipoService();
    
    dialogCadastroLocalidadeVeiculo: boolean = false;
    editaLocalidade: boolean = false;
    localidadeVeiculo: VeiculoLocalidade = new VeiculoLocalidade();
    localidadesVeiculoHeader: any[] = [
        { text: '', value:'actions' ,sortable: false, class: 'action'},
        { text: 'País', value: 'pais'},
        { text: 'Estado', value: 'estado'},
        { text: 'Município', value: 'municipio'}
    ];

    validEditoria: boolean = true;
    editorias: Editoria[] = [];
    editoriaService: EditoriaService = new EditoriaService();

    veiculoEditoria: VeiculoEditoria = new VeiculoEditoria();
    veiculoEditoriaHeader: any[] = [
        { text: '', value:'actions' ,sortable: false, class: 'action'},
        { text: 'Nome', value: 'editoria.nome'}
    ];

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }

        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }

        if (this.$refs.formEditoria) {
            this.$refs.formEditoria.resetValidation();
        }
    }

    @Watch("dialog")
    Dialog() {
        if(this.dialog){
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar(){
        this.veiculoTipoService.ListarTudo().then(
            res => {
                this.veiculoTipos = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.editoriaService.ListarTudo().then(
            res => {
                this.editorias = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    AbrirDialogCadastroLocalidade(item?: VeiculoLocalidade){
        if(item){
            this.localidadeVeiculo = item;
            this.editaLocalidade = true;
        }
        else{
            this.localidadeVeiculo = new VeiculoLocalidade();
            this.editaLocalidade = false;
        }
        this.dialogCadastroLocalidadeVeiculo = true;
    }

    SalvouLocalidadeVeiculo() {
        if (!this.editaLocalidade) {
            this.item.localidades.push(this.localidadeVeiculo);
        }
        this.dialogCadastroLocalidadeVeiculo = false;
    }

    ExcluirRegistroLocalidade(localidadeVeiculo: VeiculoLocalidade) {
        const excluir = () => new Promise(() => {
            const index = this.item.localidades.findIndex(x => x.id === localidadeVeiculo.id);
            this.item.localidades.splice(index, 1);
        });
        AlertExcludeQuestion(excluir, true);
    }

    AdicionarEditoria(){
        if(this.$refs.formEditoria.validate()){
            if(this.item.editorias.find(x => x.editoriaId == this.veiculoEditoria.editoriaId)) {
                AlertSimple("Aviso!", "O item selecionado já se encontra incluso.", "warning")
                this.veiculoEditoria = new VeiculoEditoria();
                if (this.$refs.formEditoria) {
                    this.$refs.formEditoria.resetValidation();
                }
                return;
            }

            this.veiculoEditoria.editoria = this.editorias.find(x => x.id == this.veiculoEditoria.editoriaId)!;
            this.item.editorias.push(this.veiculoEditoria);
            this.veiculoEditoria = new VeiculoEditoria();
            if (this.$refs.formEditoria) {
                this.$refs.formEditoria.resetValidation();
            }
        }
    }

    ExcluirEditoria(veiculoEditoria: VeiculoEditoria) {
        const excluir = () => new Promise(() => {
            const index = this.item.editorias.findIndex(x => x.id === veiculoEditoria.id);
            this.item.editorias.splice(index, 1);
        });
        AlertExcludeQuestion(excluir, true);
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            this.loading = true;
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }
    
    Close(){
        this.dialog = false;
    }
}
