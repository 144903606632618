import { Editoria, Veiculo, NoticiaAudio, NoticiaImagem, NoticiaSemelhante, NoticiaVideo } from ".";

export class Noticia implements Shared.IEntity{

    id: number = 0;
    codigoIntegracao: number = 0;
    url: string = "";
    dominio: string = "";
    titulo: string = "";
    subtitulo: string = "";
    autor: string = "";
    conteudo: string = "";
    tamanhoConteudo: number = 0;
    veiculoId: number = 0;
    veiculo!: Veiculo;
    editoriaId: number = 0;
    editoria!: Editoria;
    dataColeta: string = "";
    dataPublicacao: string = "";
    duracao?: string;
    idioma: string = "";
    chapeu: string = "";
    localidade: string = "";
    pagina: number = 0;
    
    imagens: NoticiaImagem[] = [];
    videos: NoticiaVideo[] = [];
    audios: NoticiaAudio[] = [];

    constructor(model?: Noticia){

        if(!model)
            return;

        this.id = model.id;
        this.url = model.url;
        this.codigoIntegracao = model.codigoIntegracao;
        this.dominio = model.dominio;
        this.titulo = model.titulo;
        this.subtitulo = model.subtitulo;
        this.autor = model.autor;
        this.conteudo = model.conteudo;
        this.tamanhoConteudo = model.tamanhoConteudo;
        this.veiculoId = model.veiculoId;
        this.veiculo = model.veiculo;
        this.editoriaId = model.editoriaId;
        this.editoria = model.editoria;
        this.dataColeta = model.dataColeta;
        this.dataPublicacao = model.dataPublicacao;
        this.duracao = model.duracao;
        this.idioma = model.idioma;
        this.chapeu = model.chapeu;
        this.localidade = model.localidade;
        this.pagina = model.pagina;
        
        this.imagens = model.imagens;
        this.videos = model.videos;
        this.audios = model.audios;
    }
}