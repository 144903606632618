
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { Editoria, Noticia, NoticiaAudio, NoticiaVideo } from '@/core/models/geral'
import { EditoriaService, VeiculoService, NoticiaService } from '@/core/services/geral';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { NoticiaImagem } from '@/core/models/geral/NoticiaImagem';
import { Veiculo } from '@/core/models/geral/Veiculo';
import { CrudBase } from '@/core/models/shared';

@Component
export default class CadastroNoticia extends CrudBase {
    @Prop() item!: Noticia;
    @Prop() private value!: string;

    itemOriginal!: Noticia;    
    service: NoticiaService = new NoticiaService();
    $refs!: {
        form: HTMLFormElement
    }
    
    veiculos: Veiculo[] = [];
    veiculoService: VeiculoService = new VeiculoService();

    editorias: Editoria[] = [];
    editoriaService: EditoriaService = new EditoriaService();

    imagem: NoticiaImagem = new NoticiaImagem();
    editaImagem: boolean = false;
    dialogCadastroImagem: boolean = false;
    imagemHeaders: any[] = [
        { text: '', value:'actions' ,sortable: false, class: 'action', use: true },
        { text: 'Legenda', value: 'legenda', use: true },
        { text: 'Url', value: 'url', use: true },
        { text: 'Arquivo', value: 'arquivo', sortable: false}
    ];

    video: NoticiaVideo = new NoticiaVideo();
    editaVideo: boolean = false;
    dialogCadastroVideo: boolean = false;
    videoHeaders: any[] = [
        { text: '', value:'actions' ,sortable: false, class: 'action', use: true },
        { text: 'Legenda', value: 'legenda', use: true },
        { text: 'Url', value: 'url', use: true },
        { text: 'Arquivo', value: 'arquivo', sortable: false}
    ];

    audio: NoticiaAudio = new NoticiaAudio();
    editaAudio: boolean = false;
    dialogCadastroAudio: boolean = false;
    audioHeaders: any[] = [
        { text: '', value:'actions' ,sortable: false, class: 'action', use: true },
        { text: 'Legenda', value: 'legenda', use: true },
        { text: 'Url', value: 'url', use: true },
        { text: 'Arquivo', value: 'arquivo', sortable: false}
    ];

    //CONFORME FOR ADICIONANDO O ARQUIVO TODOS TERAO DE ESTAR NESSA LISTA
    arquivos: any = [];

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch('item')
    async ItemWatch(){
        if(this.item.id > 0){            
            this.itemOriginal = jiff.clone(this.item);
        }

        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    CarregaSimilares() {
        
    }

    Carregar(){
        this.veiculoService.ListarTudo().then(
            res => {
                this.veiculos = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.editoriaService.ListarTudo().then(
            res => {
                this.editorias = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    AbrirDialogCadastroImagem(item?: NoticiaImagem){
        if(item){
            this.imagem = item;
            this.editaImagem = true;
        }
        else{
            this.imagem = new NoticiaImagem();
            this.editaImagem = false;
        }
        this.dialogCadastroImagem = true;
    }

    SalvouImagem(imagem: NoticiaImagem) {
        if (!this.editaImagem) {
            this.item.imagens.push(imagem);
        }
        this.dialogCadastroImagem = false;
    }

    ExcluirRegistroImagem(imagem: NoticiaImagem) {
        const excluir = () => new Promise(() => {
            const index = this.item.imagens.indexOf(imagem);
            this.item.imagens.splice(index, 1);
        });
        AlertExcludeQuestion(excluir, true);
    }

    AbrirDialogCadastroVideo(video?: NoticiaVideo){
        if(video){
            this.video = video;
            this.editaVideo = true;
        }
        else{
            this.video = new NoticiaVideo();
            this.editaVideo = false;
        }
        this.dialogCadastroVideo = true;
    }

    SalvouVideo(video: NoticiaVideo) {
        if (!this.editaVideo) {
            this.item.videos.push(video);
        }
        this.dialogCadastroVideo = false;
    }

    ExcluirRegistroVideo(video: NoticiaVideo) {
        const excluir = () => new Promise(() => {
            const index = this.item.videos.indexOf(video);
            this.item.videos.splice(index, 1);
        });
        AlertExcludeQuestion(excluir, true);
    }

    AbrirDialogCadastroAudio(audio?: NoticiaAudio){
        if(audio){
            this.audio = audio;
            this.editaAudio = true;
        }
        else{
            this.audio = new NoticiaAudio();
            this.editaAudio = false;
        }
        this.dialogCadastroAudio = true;
    }

    SalvouAudio(audio: NoticiaAudio) {
        if (!this.editaAudio) {
            this.item.audios.push(audio);
        }
        this.dialogCadastroAudio = false;
    }

    ExcluirRegistroAudio(audio: NoticiaAudio) {
        const excluir = () => new Promise(() => {
            const index = this.item.audios.indexOf(audio);
            this.item.audios.splice(index, 1);
        });
        AlertExcludeQuestion(excluir, true);
    }

    AtribuirArquivo(arquivo: any){
        if(!this.arquivos.map(x => x.name).find(x => x == arquivo.name))
            this.arquivos.push(arquivo);
    }

    DownloadDocumento(id: number, itemId: number, tipo: string){
        this.service.DownloadDocumento(id, itemId, tipo).then(
            res => {
                this.service.DownloadArquivo(res);
            },
            err => AlertSimple('Aviso', this.service.ErroArquivo(err), 'error')
        )
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            this.loading = true;

            (this.item.id > 0 ? this.service.Salvamento(this.item.id, patchModel, this.arquivos) : this.service.Salvamento(this.item.id, this.item, this.arquivos)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.Salvou();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }

    Close(){
        this.arquivos = [];
        this.dialog = false;
    }
}
