
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { Usuario } from '@/core/models/geral'
import { UsuarioPerfilService, UsuarioService } from '@/core/services/geral';
import { AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { ArquivoService } from '@/core/services/shared';
import { UsuarioPerfil } from '@/core/models/geral';
import { CrudBase } from '@/core/models/shared';

@Component
export default class CadastroUsuario extends CrudBase {
    @Prop() private value!: string;
    @Prop() item!: Usuario;

    itemOriginal!: Usuario;    
    service: UsuarioService = new UsuarioService();
    $refs!: {
        form: HTMLFormElement,
        file1: HTMLFormElement
    }

    foto: any = null;

    perfil: UsuarioPerfil[] = [];
    perfilService: UsuarioPerfilService = new UsuarioPerfilService();

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if(this.dialog){
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }

        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar(){
        this.perfilService.ListarTudo().then(
            res => {
                this.perfil = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    async LoadImage(){
        this.foto = this.$refs.file1.files[0];
        if (!this.foto)
            return;
        const arquivoService = new ArquivoService();
        const dados = await arquivoService.Ler(this.foto);
        this.item.foto = dados.replace(/^[^,]*,/, "");
    }
    RemoveImage(){
        this.item.foto = null;
        this.foto = null;
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            this.loading = true;
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.Salvou();
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }
    
    Close(){
        this.dialog = false;
    }
}
