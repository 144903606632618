<template>
    <div :id="key"></div>
</template>

<script>

export default {
    props:{
        //[Nome, Valor]
        //["Teste", 100]
        dados: {
            required: true
        }
    },
    data: () => ({
        key: 'ada4sd8a9sd779654'
    }),
    created() {
        this.key = Math.random(36).toString();
    },
    mounted(){
        google.load('visualization', '1', {
            'packages': ['geochart', 'table']
        });
        google.charts.setOnLoadCallback(this.draw);
    },
    methods: {
        draw(){
            var dados_chart = new google.visualization.arrayToDataTable(this.dados);
            var options = {
                region: 'BR',
                resolution: 'provinces',
            };
            var chart = new google.visualization.GeoChart(document.getElementById(this.key));
            chart.draw(dados_chart, options);
        }
    }
}
</script>