
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { AlertExcludeQuestion, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { CrudBase } from '@/core/models/shared';
import { Selecao, SelecaoClienteNoticia, Veiculo, VeiculoTipo } from '@/core/models/geral';
import { ClienteService, NoticiaService, SelecaoService, VeiculoService, VeiculoTipoService } from '@/core/services/geral';

@Component
export default class CadastroSelecao extends CrudBase {
    @Prop() private value!: string;
    @Prop() item!: Selecao;

    itemOriginal!: Selecao;
    service: SelecaoService = new SelecaoService();
    $refs!: {
        form: HTMLFormElement
    }

    isClienteLoading: boolean = false;
    clienteService: ClienteService = new ClienteService();
    onSearchCliente: any = null;
    clientes: any[] = [];
    assuntos: any[] = [];
    termos: any[] = [];

    noticiaService: NoticiaService = new NoticiaService();
    filtro: any = {
        busca: "",
        assuntoId: null,
        termoId: null,
        tipoVeiculoId: null,
        veiculoId: null,
        dataPublicacaoInicial: new Date().toYYYYMMDD() + "T00:00:00",
        dataPublicacaoFinal: new Date().toYYYYMMDD() + "T23:59:59",
        dataColetaInicial: null,
        dataColetaFinal: null
    }
    listagem: any = [];
    selecionados: any = [];
    listagemHeaders: any = [
        { text: '', value: 'actions' },
        { text: 'Titulo', value: 'titulo' },
        { text: 'Veículo', value: 'veiculo' },
        { text: 'Publicação', value: 'dataPublicacao' },
        { text: 'Coleta', value: 'dataColeta' },
    ];

    tipoVeiculoService: VeiculoTipoService = new VeiculoTipoService();
    tipoVeiculos: VeiculoTipo[] = [];

    veiculoService: VeiculoService = new VeiculoService();
    veiculos: Veiculo[] = [];

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
            this.tabActive = 1;
        }

        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }

        this.clientes.push(this.item.cliente);

        this.item.noticias.forEach(x => {            
            let noticia = x.clienteNoticia.noticia;
            //APENAS PARA CORRIGIR A VARIAVEL QUE É UTILIZADA PARA INCLUIR E EXCLUIR ITENS
            noticia[`clienteNoticiaId`] = x.clienteNoticia.id;
            this.selecionados.push(noticia);
        });
    }

    @Watch("dialog")
    Dialog() {
        if(this.dialog){
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    @Watch('onSearchCliente')
    searchCliente (val: string) {
        if (this.item.clienteId) return;
        if (this.isClienteLoading) return;
        if (!val) return;
        this.isClienteLoading = true
        this.clienteService.AutoComplete(val).then(
            res => {
                this.clientes = res.data
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isClienteLoading = false));
    }

    @Watch("item.clienteId")
    WatchCliente(){
        this.filtro.assuntoId = null;
        this.assuntos = [];
        if(this.item.clienteId > 0){
            this.clienteService.Assuntos(this.item.clienteId).then(
                res => {
                    this.assuntos = res.data;
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    @Watch("filtro.assuntoId")
    WatchAssunto(){
        this.filtro.termoId = null;
        this.termos = [];
        if(this.filtro.assuntoId > 0){
            this.termos = this.assuntos.find(x => x.id == this.filtro.assuntoId).itens;
        }
    }

    Carregar(){
        this.veiculoService.ListarTudo().then(
            res => {
                this.veiculos = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )

        this.tipoVeiculoService.ListarTudo().then(
            res => this.tipoVeiculos = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    ListarNoticia(){
        const promise1 = this.noticiaService.Lista(this.filtro.busca, this.item.clienteId, this.filtro.assuntoId, this.filtro.termoId, this.filtro.veiculoId, this.filtro.tipoVeiculoId, this.filtro.dataPublicacaoInicial, this.filtro.dataPublicacaoFinal, this.filtro.dataColetaInicial, this.filtro.dataColetaFinal).then(
            res => {
                this.listagem = res.data
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    ExcluirNoticiaSelecionada(item: any){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.selecionados.indexOf(item);
                context.selecionados.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    Salvar(){
        if (this.$refs.form.validate()) {

            let adicionar = this.selecionados.map(y => y.clienteNoticiaId).filter(x => !this.item.noticias.map(x => x.clienteNoticiaId).includes(x));
            let remover = this.item.noticias.map(x => x.clienteNoticiaId).filter(x => !this.selecionados.map(y => y.clienteNoticiaId).includes(x));

            adicionar.forEach(x => {
                let selecaoNoticia = new SelecaoClienteNoticia();
                selecaoNoticia.selecaoId = this.item.id;
                selecaoNoticia.clienteNoticiaId = x;
                this.item.noticias.push(selecaoNoticia);
            });

            remover.forEach(x => {
                const index = this.item.noticias.findIndex(y => y.clienteNoticiaId == x);
                this.item.noticias.splice(index, 1);
            });

            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            this.loading = true;
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }
    
    Close(){
        this.selecionados = [];
        this.tabActive = 0;
        this.dialog = false;
    }
}
